var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('div',{staticClass:"d-flex my-6"},[_c('v-btn',{attrs:{"color":"accent darken-4","disabled":!_vm.check_add_event()},on:{"click":_vm.go_to_add_event}},[_vm._v(" Добавить мероприятие ")]),_c('ClearLocalStorage',{attrs:{"default_cols":_vm.cols_default,"storage_name":_vm.cols_storage_name,"cols_list":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols_default=$event}}})],1),_vm._l((_vm.updated_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:_vm.$style.table,attrs:{"headers":_vm.updated_cols,"items":_vm.events,"loading":_vm.events_loading,"options":_vm.table_options,"hide-default-footer":"","no-data-text":"Список мероприятий пуст.\n              Добавьте мероприятие в заявку, чтобы отправить заявку на согласование"},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('ChipStatus',{attrs:{"status":_vm.aplication_chip_status(item)}},[_vm._v(" "+_vm._s(item.status.name)+" ")])],1)]}},{key:"item.date_start",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("date")(item.date_start))+" ")])]}},{key:"item.date_end",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("date")(item.date_end))+" ")])]}},{key:"item.size_audience_reached",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.size_audience_reached_name(item))+" ")])]}},{key:"item.event_type",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.$style.typeText},[_vm._v(" "+_vm._s(_vm.event_type_name(item))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":"","color":"accent darken-4","disabled":!_vm.edit_status_check(item)},on:{"click":function($event){$event.stopPropagation();return _vm.edit_event(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"icon":"","small":"","color":"secondary darken-4","disabled":!_vm.delete_status_check(item)},on:{"click":function($event){$event.stopPropagation();return _vm.delete_event(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }